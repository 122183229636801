import React from "react";
import {
    Javascript,
    HTML5,
    CSS3,
    PostgreSQL,
    Git,
    ReactIcon,
    ThreeJS,
    TypeScript,
} from "../LanguagesSVGs";

export type Project = {
    title: string;
    description1: string;
    description2: string;
    link: string;
    image: string;
    mobileImage: string;
    skills: string[];
    // skills: React.ReactElement<SVGElement>[];
};

export const convertStringToSvg = (skill: string) => {
    switch (skill) {
        case "TypeScript":
            return (
                <TypeScript
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "HTML5":
            return (
                <HTML5
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "CSS3":
            return (
                <CSS3
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "React":
            return (
                <ReactIcon
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "PostgreSQL":
            return (
                <PostgreSQL
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "Git":
            return (
                <Git
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        case "ThreeJS":
            return (
                <ThreeJS
                    hoveredLanguage=""
                    onHover={() => {}}
                    leaveHover={() => {}}
                />
            );
        default:
            return;
            <Javascript
                hoveredLanguage=""
                onHover={() => {}}
                leaveHover={() => {}}
            />;
    }
};

export const convertListToSvgList = (skills: string[]) => {
    let svgList: React.ReactElement<SVGElement>[] = [];
    skills.forEach((skill) => {
        const svgElement = convertStringToSvg(skill);
        if (svgElement) {
            svgList.push(svgElement);
        }
    });

    return svgList;
};

export const ProjectsList: Project[] = [
    {
        title: "Pokedex App",
        description1:
            "Pokédex App is a powerful tool built with React Native and TypeScript. The app includes a fully-featured Pokédex, as well as detailed information on each Pokémon, including stats, types, abilities, and evolutions, all of which are kept up-to-date with data directly from PokéAPI. Additionally, the app makes it easy to find specific Pokémon through its search function, which lets you quickly locate any Pokémon by name or number.",
        description2:
            "For trainers who love strategy, the app also offers a team builder that enables users to create and save custom Pokémon teams. This feature helps you plan your battles by selecting Pokémon based on their strengths, types, and moves, ensuring you have the perfect lineup for any challenge. Whether you're a casual fan or a competitive player, this app is designed to be the ultimate resource for Pokémon training and team building.",
        link: "https://simonmorrell.dev/projects",
        image: "/pokedexApp.png",
        mobileImage: "/portfoliomobile.png",
        skills: ["TypeScript", "HTML5", "CSS3", "React", "Git"],
    },
    {
        title: "Gameboy Configurator",
        description1:
            "Interactive 3D configurator, where you can build your perfect customised Game Boy console from the ground up. The intuitive interface lets you explore a variety of customization options in real-time. As you select different colours and components, watch your Game Boy transform before your eyes in a high-quality 3D model. Rotate, zoom, and view your creation from every angle to ensure it meets your vision.",
        description2:
            "Powered by a meticulously crafted backend infrastructure, featuring a custom API and PostgreSQL (PSQL) database. The custom API serves as the backbone of the website, facilitating real-time data retrieval for the dynamic ThreeJS 3D configurator, managing extensive customization options. The PostgreSQL database efficiently stores and manages all website-related data, supporting fast and reliable access to customization options, products and order histories.",
        link: "https://solomonmods-gameboy-configurator.onrender.com/",
        image: "/gameboyconfigurator.png",
        mobileImage: "/gameboyconfiguratormobile.png",
        skills: ["TypeScript", "HTML5", "CSS3", "React", "PostgreSQL", "Git"],
    },
    {
        title: "Portfolio",
        description1:
            "My front-end developer portfolio, a digital space where creativity meets technical expertise. This site is a testament to my passion for crafting visually appealing and highly functional web interfaces. Using cutting-edge technologies like TypeScript, React, Redux, and Gatsby, I’ve built a platform that not only showcases my projects but also reflects my commitment to modern web development practices.",
        description2:
            "In my portfolio, you’ll find a variety of projects that demonstrate my proficiency in front-end technologies and my ability to create seamless, responsive user experiences. Each project highlights my skills in designing intuitive interfaces, managing complex state, and optimizing web performance. Explore my work to see how I transform ideas into elegant web applications, and discover how my innovative approach to front-end development can bring your digital projects to life.",
        link: "https://simonmorrell.dev/",
        image: "/portfolio1.png",
        mobileImage: "/portfoliomobile.png",
        skills: ["TypeScript", "HTML5", "CSS3", "React", "Git"],
    },
    {
        title: "Solomon Mods Website",
        description1:
            "Solomon Mods is a unique platform where retro gaming meets modern web development. This site is an extension of the interactive Game Boy configurator, incorporating a real-time 3D model powered by Three.js. The site also includes a gallery of previous mods and customer reviews showcasing the quality of work. The site will soon feature a shop where you can purchase pre-modded consoles directly, as it transitions from its current home on Etsy.",
        description2:
            "The website is built with React, Redux, TypeScript, Three.js, and Gatsby to ensure a fast, responsive, and engaging user experience. React and Redux enable dynamic interfaces and efficient state management, while TypeScript ensures type safety and code reliability. Three.js delivers real-time 3D visualizations, and Gatsby enhances performance with static site generation for quick load times and seamless navigation.",
        link: "https://solomon-mods-web.onrender.com/",
        image: "/solomon-mods.png",
        mobileImage: "/solomon-mods-mobile.png",
        skills: ["TypeScript", "HTML5", "CSS3", "React", "ThreeJS", "Git"],
    },

    {
        title: "Coming Soon",
        description1:
            "I am currently working on new projects that will be released soon. Stay tuned for more details! In the meantime, feel free to explore my existing projects and learn more about my skills and experience.",
        description2: "",
        link: "https://simonmorrell.dev/projects",
        image: "/comingSoon.png",
        mobileImage: "/gameboyconfiguratormobile.png",
        skills: [],
    },
];
