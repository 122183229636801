import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ProjectsList } from "../components/Projects/ProjectsList";

interface Project {
    title: string;
    description1: string;
    description2: string;
    link: string;
    image: string;
    mobileImage: string;
    skills: string[];
}

export interface ProjectState {
    selectedProject: string;
    mobileImage: string;
    currentList: Project[];
    animate: string;
    displayModal: boolean;
}

const initialState: ProjectState = {
    selectedProject: "",
    mobileImage: "",
    currentList: ProjectsList,
    animate: "",
    displayModal: false,
};

export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setSelectedProject: (state, action: PayloadAction<string>) => {
            state.selectedProject = action.payload;
        },
        setMobileIcon: (state, action: PayloadAction<string>) => {
            state.mobileImage = action.payload;
        },
        setCurrentList: (state, action: PayloadAction<Project[]>) => {
            state.currentList.splice(
                0,
                state.currentList.length,
                ...action.payload
            );
        },
        setAnimate: (state, action: PayloadAction<string>) => {
            state.animate = action.payload;
        },
        setDisplayModal: (state, action: PayloadAction<boolean>) => {
            state.displayModal = action.payload;
        },
    },
});

export const {
    setSelectedProject,
    setMobileIcon,
    setCurrentList,
    setAnimate,
    setDisplayModal,
} = projectsSlice.actions;

export const selectSelectedProject = (state: { projects: ProjectState }) =>
    state.projects.selectedProject;
export const selectMobileIcon = (state: { projects: ProjectState }) =>
    state.projects.mobileImage;
export const selectCurrentList = (state: { projects: ProjectState }) =>
    state.projects.currentList;
export const selectAnimate = (state: { projects: ProjectState }) =>
    state.projects.animate;
export const selectDisplayModal = (state: { projects: ProjectState }) =>
    state.projects.displayModal;

export default projectsSlice.reducer;
